import { pickBrand } from "@/apollo/queries/PickBrand.gql";

const slashTo = toStr =>
  toStr
    ? toStr === "index.html" || toStr === "/index.html"
      ? "/"
      : toStr.indexOf("/") === 0
      ? toStr
      : `/${toStr}`
    : "";

const extLink = link =>
  typeof link === "string" && (link.startsWith("http:") || link.startsWith("https:") || link.startsWith("//"));
/**
 * Fixes a link by ensuring it ends with a slash if necessary, or returns false if the link is not valid.
 * @param {Object} attrs - The attributes object containing either 'to' or 'href' properties.
 * @param {string} [attrs.to] - The target link property.
 * @param {string} [attrs.href] - An alternate way to specify the link property.
 * @returns {string|boolean} - Returns the fixed link if valid, otherwise returns false.
 */
export const fixLink = attrs => {
  const link = attrs.to || attrs.href;
  if (!link) return false;
  return extLink(link) ? link : slashTo(link);
};

export function useLead(page) {
  const loading = ref(false);

  const runClick = (link, router, e) => {
    if (e) e.preventDefault();
    router.push(link);
    return false;
  };

  const makeClick = (attrs, router) => {
    if (!!attrs.nick || !!attrs.soft)
      return { click: event => (!unref(loading) ? lead(attrs.nick, attrs.soft) : false) };

    if (router) {
      const link = fixLink(attrs, router);
      if (!extLink(link)) {
        return {
          click: e => runClick(link, router, e),
        };
      }
    }
    return {};
  };

  const makeAttrs = (props, router) => {
    const attrs = _.clone(props);
    const link = fixLink(attrs);
    delete attrs.href;
    delete attrs.to;
    if (link && typeof link === "string") {
      if (extLink(link)) attrs.href = link;
      else if (router) attrs.href = link; // for menus
      else attrs.to = link;
    }

    // if (typeof attrs.class === "string") attrs.class = attrs.class.split(" ").filter(v => v);
    // else if (typeof attrs.class === "object" && !Array.isArray(attrs.class))
    //   attrs.class = Object.keys(attrs.class)
    //     .map(k => ({ k: attrs.class[k] }))
    //     .filter(v => v);
    // else attrs.class = attrs.class || [];
    attrs.class = attrs.meta?.hideOn ? [`d-${attrs.meta?.hideOn}-none`] : attrs.class ? [attrs.class] : [];
    if (!props.href && !props.to) {
      attrs.external = true;
      attrs.rel = "external nofollow noopener noreferrer";
      if (!attrs.nick && !attrs.soft) return { custom: true };
      attrs.class.push({ ldsdualring: unref(loading) });
      attrs.class.push("lead");
      attrs.href = "#"; // works now
    }
    attrs.class.push("inner-link");

    return _.omitBy(router ? _.pick(attrs, ["to", "href", "class"]) : attrs, v => !v);
  };

  const config = useRuntimeConfig();
  let url = unref(page)?.download?.url;
  const defaultbrandnick = unref(page)?.defaultNick;
  const isMatomo = config.public?.matomo && config.public?.matomo?.siteId;
  const isGtm = config.public?.gtm && config.public?.gtm?.id;
  const trackLead = async (nick = null, soft = null) => {
    if (url) {
      return; // no civilian tracking
    }
    const uri = unref(page).uri === "index.html" ? "/" : "/" + unref(page).uri;
    const variables = { nick, soft, defaultbrandnick, hostname: unref(page).hostname, uri };
    const { data } = await useAsyncQuery(pickBrand, variables);
    if (!data || !Object.keys(data).length) {
      console.log("pickBrand failed");
      return;
    }
    const res = data.value.pickBrand;
    url = res?.download || res?.downloads?.casino;

    if (window && (!res || res.tracked)) {
      if (unref(page).anl?.type === "gtm")
        (window.dataLayer = window.dataLayer ?? []).push({
          event: "lead",
          brand: res.title,
          vendor: res.Vendor,
          price: res.popularity,
          content_type: unref(page).typename,
        });
      else if (unref(page).anl?.type === "matomo")
        (window._paq = window._paq ?? []).push(["trackEvent", "downloads", res.title, res.Vendor, res.popularity]);
    }
    // const event = isMatomo
    //   ? ["trackEvent", "downloads", res.title, res.Vendor, res.popularity] // category, action, [name], [value]
    //   : {
    //       event: "lead",
    //       brand: res.title,
    //       vendor: res.Vendor,
    //       price: res.popularity,
    //       content_type: unref(page).typename,
    //     };

    // if (!res || res.tracked)
    //   isMatomo
    //     ? window._paq.push(event)
    //     : isGtm
    //     ? useGtm()?.trackEvent(event)
    //     : console.log("no or bad analytics type");
  };

  const redirect = () => {
    url
      ? navigateTo(url, {
          external: true,
        })
      : console.log("no url to redirect");
  };

  const lead = async (nick = null, soft = null) => {
    loading.value = true;
    await trackLead(nick, soft);
    setTimeout(() => (loading.value = false), 20000);
    redirect();
  };
  const loadingLead = () => loading;
  return { lead, loadingLead, makeAttrs, makeClick, loading };
}
